import React, { useEffect, useState } from 'react';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import { triggerNetlifyBuild } from './graphql/mutations';
import { checkNetlifyBuildStatus } from './graphql/queries';
import styles from './NetlifyDeploymentButton.module.css';
import LoadingSpinner from './LoadingSpinner';

const STATE_TO_PRETTY_TEXT_MAP = {
  IN_PROGRESS: 'Deploying...',
  FAILED: 'Deployment Failed :/',
  SUCCEEDED: 'Deploy was successful!',
  NOT_STARTED: '',
};

export default function NetlifyDeploymentButton({ environment }) {
  const [deploymentState, setDeploymentState] = useState('NOT_STARTED');
  const [deploymentId, setDeploymentId] = useState(null);
  const [deploymentStartTime, setDeploymentStartTime] = useState(null);

  async function triggerNetlifyDeployment() {
    try {
      setDeploymentState('IN_PROGRESS');
      const user = await Auth.currentUserInfo();
      const result = await API.graphql(
        graphqlOperation(triggerNetlifyBuild, {
          input: {
            environment: environment.toUpperCase(),
            triggeredBy: user.attributes.email,
          },
        }),
      );
      setDeploymentId(result.data.triggerNetlifyBuild.buildId);
      setDeploymentStartTime(new Date());
    } catch (e) {
      console.log(e);
      setDeploymentState('FAILED');
    }
  }

  useEffect(() => {
    if (deploymentState !== 'IN_PROGRESS' || !deploymentId) {
      return () => {};
    }

    const intervalId = setInterval(async () => {
      try {
        const result = await API.graphql(
          graphqlOperation(checkNetlifyBuildStatus, {
            input: {
              buildId: deploymentId,
            },
          }),
        );
        if (result.data.checkNetlifyBuildStatus.state === 'SUCCEEDED') {
          clearInterval(intervalId);
        }
        setDeploymentState(result.data.checkNetlifyBuildStatus.state);
      } catch (e) {
        console.log(e);
        setDeploymentState('FAILED');
      }
    }, 15000);

    return () => {
      clearInterval(intervalId);
    };
  }, [deploymentState, deploymentId]);

  return (
    <div>
      <div className={styles.buttonRow}>
        <button
          className={styles.deployButton}
          disabled={deploymentState !== 'NOT_STARTED'}
          onClick={() => triggerNetlifyDeployment()}
          type="button"
        >
          Deploy
        </button>
        {deploymentState === 'IN_PROGRESS' && (
          <LoadingSpinner className={styles.spinner} />
        )}
      </div>
      <p>{STATE_TO_PRETTY_TEXT_MAP[deploymentState]}</p>

      {deploymentState === 'IN_PROGRESS' && deploymentStartTime && (
        <p>Started at: {deploymentStartTime.toLocaleString()}</p>
      )}
      {(deploymentState === 'SUCCEEDED' || deploymentState === 'FAILED') && (
        <>
          {deploymentStartTime && (
            <p>Last deployed at: {deploymentStartTime.toLocaleString()}</p>
          )}
          <button
            className={styles.resetButton}
            onClick={() => {
              setDeploymentState('NOT_STARTED');
              setDeploymentId(null);
              setDeploymentStartTime(null);
            }}
            type="button"
          >
            Reset
          </button>
        </>
      )}
    </div>
  );
}
