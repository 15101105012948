import React from 'react';
import Header from './Header';
import NetlifyDeploymentButton from './NetlifyDeploymentButton';
import styles from './App.module.css';

function App({ user, signOut }) {
  return (
    <div className={styles.wrapper}>
      <Header user={user} signOut={signOut} />
      <main className={styles.main}>
        <h1 className={styles.header}>Consumer Website Deployment Tool</h1>
        <p className={styles.description}>
          Use this tool to re-build our farmersfridge.com websites, which will
          refresh the data used for fridge locations, menu items, job postings,
          and more. This tool clears the Netlify cache on build, which can be
          useful in cases where Netlify or Gatsby JS code is not properly
          recognizing new data
        </p>
        <div className={styles.environments}>
          <div className={styles.environment}>
            <h2>
              Preview Site{' '}
              <a
                href="https://beta.farmersfridge.com"
                target="_blank"
                rel="noreferrer"
              >
                Link
              </a>
            </h2>
            <NetlifyDeploymentButton environment="Staging" />
          </div>
          <div className={styles.environment}>
            <h2>
              Production Site{' '}
              <a
                href="https://www.farmersfridge.com"
                target="_blank"
                rel="noreferrer"
              >
                Link
              </a>
            </h2>
            <NetlifyDeploymentButton environment="Production" />
          </div>
        </div>
        <p>Note: deployments can take up to 10 minutes to complete</p>
      </main>
    </div>
  );
}

export default App;
