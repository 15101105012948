import React from 'react';
import styles from './Header.module.css';

export default function Header({ user, signOut }) {
  return (
    <header className={styles.header}>
      <div className={styles.helloMsg}>
        Hello, {user?.attributes.email.split('@')[0]}
      </div>
      <button className={styles.signoutButton} onClick={signOut} type="button">
        Sign out
      </button>
    </header>
  );
}
