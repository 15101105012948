import React from 'react';
import ReactDOM from 'react-dom/client';
import 'normalize.css';
import { Amplify, Auth } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';
import App from './App';
import './index.css';

import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

const components = {
  Header() {
    return (
      <h1
        style={{ color: '#1b6100', textAlign: 'center', margin: '0 0 32px 0' }}
      >
        Farmer&apos;s Fridge Website Deployment Tool
      </h1>
    );
  },
};

function AuthenticatedApp() {
  const services = {
    async handleSignUp(formData) {
      let { username } = formData;
      const { password, attributes } = formData;
      username = username.toLowerCase();
      attributes.email = attributes.email.toLowerCase();
      if (attributes.email.split('@')[1] !== 'farmersfridge.com') {
        throw new Error('Error: must use an farmersfridge.com email');
      }
      return Auth.signUp({
        username,
        password,
        attributes,
        autoSignIn: {
          enabled: true,
        },
      });
    },
  };

  return (
    <Authenticator
      components={components}
      services={services}
      initialState="signIn"
    >
      {({ user, signOut }) => <App user={user} signOut={signOut} />}
    </Authenticator>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthenticatedApp />
  </React.StrictMode>,
);
