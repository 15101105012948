/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const checkNetlifyBuildStatus = /* GraphQL */ `
  query CheckNetlifyBuildStatus($input: NetlifyBuildStatus) {
    checkNetlifyBuildStatus(input: $input) {
      buildId
      state
      __typename
    }
  }
`;
