import React from 'react';
import styles from './LoadingSpinner.module.css';

export default function LoadingSpinner({ className }) {
  return (
    <svg
      className={`${styles.spinner} ${className}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 66 66"
      role="presentation"
    >
      <title>Loading...</title>
      <circle cx="33" cy="33" r="30" />
    </svg>
  );
}
